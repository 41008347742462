<template>

<div>
   <big-title>
      Mis <span>proyectos</span>.
   </big-title>

  <vx-card title="Ingresos esperados en el mes." class="mb-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <p>
          El monto de ingresos esperados en el mes que se presenta a continuación considera únicamente los proyectos que fueron 
          instalados y entregados previamente, y que además se encuentran al corriente, es decir, que al momento no tienen ningún retraso de más de 11 días. 
          Los proyectos que se encuentren en instalación, fondeo o firma de contratos serán considerados una vez que hayan concluido con el proceso.  
        </p>
        <!-- <p class="">Suma de ingresos esperados por pagos del día de corte 2: <span class="bold"> {{explicitMoneyFormat(estimatedCut2, 2)}} </span></p> -->
        <!-- <p class="mt-2">Suma de ingresos esperados por pagos del día de corte 16: <span class="bold"> {{explicitMoneyFormat(estimatedCut16, 2)}} </span></p> -->
        <p class="mt-2">Suma de ingresos esperados en el mes: <span class="bold"> {{explicitMoneyFormat(totalEstimated, 2)}} </span></p>
      </div>
    </div>
  </vx-card>

   <vx-card title="Tus semillas y girasoles.">
      <div class="vx-row">
         <div class="vx-col w-full">
            <p>Consulta el estado y la información más relevante sobre los proyectos en los que invertiste, ya sea de los que aún se encuentran en fondeo o
              de los que ya crecieron en grandes girasoles. ¡En cualquiera de nuestros proyectos el sol brilla para todos!</p>
         </div>
      </div>

      <div class="vx-row mt-5">
            <div class="vx-col md:w-1/3 w-full">
                <vs-select
                  placeholder="Seleccione"
                  multiple
                  label="Estado de proyecto"
                  class="w-full"
                  v-model="statusFilterSelected">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in statusOptions" />
                </vs-select>
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <div class="flex flex-wrap">
                <vs-button @click="getProjectsList" color="dark" class="mt-6 mr-1">Filtrar</vs-button>
                </div>
            </div>
          </div>

     <div class="mt-6 mb-6">
        <div class="flex md:items-end items-center justify-between flex-wrap">
           <!--p class="lg:inline-flex bold black text-3xl mr-4">Tus semillas y girasoles </p-->

           <div class="flex justify-between items-end flex-grow">
              <div class="flex flex-wrap">
                 <!-- ITEM VIEW - GRID/LIST -->
                 <!--div>
                    <feather-icon
                       icon="ListIcon"
                       @click="currentItemView='item-list-view'"
                       class="p-2 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer"
                       :svgClasses="{'text-primary stroke-current': currentItemView === 'item-list-view'}" />
                    <feather-icon
                       icon="GridIcon"
                       @click="currentItemView='item-grid-view'"
                       class="p-2 ml-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer hidden sm:inline-flex"
                       :svgClasses="{'text-primary stroke-current': currentItemView === 'item-grid-view'}" />
                 </div-->

                <p v-if="isMounted && isMobileScreen" class="px-6 py-2 mb-3 text-primary bold border-2 border-primary border-solid rounded-lg">Total de proyectos: {{projects.length}}</p>

                <vs-dropdown v-if="isMounted" vs-trigger-click class="cursor-pointer mr-6 items-per-page-handler">
                  <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                    <span class="mr-2">Mostrando {{ currentItemsPage * itemsPerPage - (itemsPerPage - 1) }} - {{ projects.length - currentItemsPage * itemsPerPage > 0 ? currentItemsPage * itemsPerPage : projects.length }} de {{ queriedItems }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="itemsPerPage=it" :key="it">
                      <span>{{it}}</span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>

                <p v-if="isMounted && !isMobileScreen" class="px-6 py-2 text-primary bold border-2 border-primary border-solid rounded-lg">{{projects.length}} proyectos en total</p>

              </div>
           </div>
        </div>
     </div>

     <!-- Vista Tabla -->
     <div id="data-list-thumb-view" class="data-list-container" v-if="currentItemView === 'item-list-view'">
        <vx-pagination :total="totalPages" v-model="currentx" v-if="isMobileScreen"></vx-pagination>

       <vs-table :sst="true" @sort="handleSort" class="mt-4" ref="table" :data="currentProjects" v-model="currentSelection" @selected="onRowSelected" :max-items="itemsPerPage" noDataText="No hay registros" >

         <template slot="thead">
           <vs-th class="bold" sort-key="id">ID</vs-th>
           <vs-th class="bold" sort-key="name">Nombre</vs-th>
           <!-- <vs-th class="bold" sort-key="cover_name">Tipo</vs-th> -->
           <vs-th class="bold" sort-key="statusSort">Estado</vs-th>
           <vs-th class="bold" sort-key="qualification">Calif.</vs-th>
           <vs-th class="bold" sort-key="percent">Tasa</vs-th>
           <vs-th class="bold" sort-key="investment">Inversión</vs-th>
           <vs-th class="bold" sort-key="monthly_payment">
            Estimado mensual
            <vx-tooltip
              title="Estimado mensual"
              color="dark"
              text="La estimación corresponde al pago esperado en el mes corriente, ya sea pago de intereses, principal o ambos. 
              Existen créditos que el pago de amortización lo hacen al vencimiento (última mensualidad), por lo tanto, será hasta ese mes 
              que recibes el pago de principal y este dato de estimación será mucho mayor que los meses anteriores.">
              <vs-chip color="dark" class="ml-2">?</vs-chip>
            </vx-tooltip>
           </vs-th>           
           <!-- <vs-th class="bold" sort-key="progress">Avance</vs-th> -->
         </template>

         <template slot-scope="{data}">
           <tbody>
           <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="font-medium">{{ tr.id }}</p>
              </vs-td>
             <vs-td>
               <p class="pl-3 bold">
                 <router-link class="bold text-dark ml-1 inline-link" :to="{name: 'proyectoInversionista', params: {id: tr.id}}">
                   {{tr.name}}
                 </router-link>
               </p>
             </vs-td>
             <!-- <vs-td>
               <p class="font-medium">{{ tr.cover_name }}</p>
               <p class="font-medium">{{ tr.subcategory }}</p>
             </vs-td> -->
             <vs-td>
               <p class="font-medium"> <span class="status-chip" :class="tr.statusClass">{{ tr.statusFormat }}</span> </p>
             </vs-td>
             <vs-td class="calif-container">
               <p class="font-medium"> <span class="qualification-circle" :class="`bg-${qualificationCode(tr.qualification)}`">{{ tr.qualification }}</span> </p>
             </vs-td>
             <vs-td>
               <p class="font-medium">{{ tr.percentFormat }}</p>
             </vs-td>
             <vs-td>
               <p class="font-medium">{{ tr.investmentFormat }}</p>
             </vs-td>
             <vs-td>
               <p class="font-medium">{{ tr.estimatedFormat }}</p>
             </vs-td>
             <!-- <vs-td>
               <p class="font-medium">{{ tr.progressFormat }}</p>
             </vs-td> -->
           </vs-tr>
           </tbody>
         </template>
       </vs-table>
       <br>
       <vx-pagination :total="totalPages" v-model="currentx"></vx-pagination>
     </div>

   </vx-card>

   <!--div v-if="currentItemView !== 'item-list-view'" class="mt-6">
      <vs-row vs-w="12">
         <template v-for="(proj, idx) in projects">
            <invested-project-card :proj="proj" v-bind:key="idx"  @on-card-clicked="goToProjectDetail" />
         </template>
      </vs-row>
   </div-->

</div>
</template>

<script>
import formatHelper from '@mixins/formatHelper';
import currencyHelper from '@mixins/currencyHelper';
import qualificationsHelper from '@mixins/qualificationsHelper';
import creditStatusesHelper from "@mixins/creditStatusesHelper";
import projectStatusesHelper from "@mixins/projectStatusesHelper";
import InvestedProjectCard from "@components/investor/invested/InvestedProjectCard";
import VxPagination from "@/vx-components/vx-pagination/VxPagination";
import ProjectStatusEnum from "@/modules/enums/ProjectStatusEnum";

export default {
   mixins: [formatHelper, currencyHelper, qualificationsHelper, creditStatusesHelper, projectStatusesHelper, ProjectStatusEnum],
   components: { InvestedProjectCard, VxPagination },
   data(){
      return {
        isFirstTime: true,
        currentItemView: 'item-list-view',
        itemsPerPage: 10,
        projects: [],
        filteredProjects: [],
        isMounted: false,
        currentSelection: null,
        itemsPerPageDefinitions : [10,25,50,100],
        coverImagesList: [],
        currentx: 1,
        mobileScreen: 576,
        estimatedCut2: 0,
        estimatedCut16: 0,
        totalEstimated: 0,
        statusFilterSelected: [
          'investment_commitment',
          'up_to_date',
          'delayed',
          'expired',
          'settled',
          'on_recovery',
          'recovered'
        ],
        statusOptions:[
          {
            value: 'investment_commitment',
            text: 'Compromiso de inversion'
          },
          {
            value: 'up_to_date',
            text: 'Al corriente'
          },
          {
            value: 'delayed',
            text: 'Retrasado'
          },
          {
            value: 'expired',
            text: 'Vencido'
          },
          {
            value: 'settled',
            text: 'Pagado'
          },
          {
            value: 'on_recovery',
            text: 'Quebrantado'
          },
          {
            value: 'recovered',
            text: 'Recuperado'
          },
          {
            value: 'refunded',
            text: 'Reembolsado'
          }
        ],
      }
   },

   async beforeMount(){
     this.isMounted = false
     this.showLoading(true)
    //  await this.getCoverImages();
     await this.getProjectsList();
     //this.setMonthEstimations();
     this.isMounted = true
     this.showLoading(false)
   },
  computed: {
     currentProjects(){
       let cmin = ((this.currentx -1) * this.itemsPerPage);
       let cmax = (cmin + this.itemsPerPage);
       return this.projects.slice(cmin, cmax);
     },
    currentItemsPage () {
      return this.currentx;
    },
    queriedItems () {
      return this.projects.length
    },
    totalPages(){
      return Math.ceil(this.projects.length / this.itemsPerPage);
    },
    isMobileScreen () {
      return this.$store.state.windowWidth <= this.mobileScreen
    },
  },
  methods: {
    handleSort(key, active) {
      if(active === 'asc'){
        this.projects.sort(function(a, b) {
          if(a[key] < b[key]){
            return -1
          }
          if(a[key] > b[key]){
            return 1
          }
          return 0;
        });
      }
      else if(active === 'desc') {
        this.projects.sort(function(a, b) {
          if(a[key] < b[key]){
            return 1
          }
          if(a[key] > b[key]){
            return -1
          }
          return 0;
        });
      }
      else {
        this.projects.sort(function(a, b) {
          return b.id - a.id;
        });
      }
      this.currentx = 1;
    },
    async getProjectsList(){
      this.showLoading(true);
       try {
          const res = await axios.post('api/inversionista/inversiones/resumen', {filterStatuses: this.statusFilterSelected});
          this.projects = this.setProjects(res.data.projects);
          this.totalEstimated = res.data.totalEstimated;          
          // se genera log de accion
          if (this.isFirstTime) {
            this.saveInvestorLogAction("LC10-18");
            this.isFirstTime = false;
          }
          this.showLoading(false);
       }
       catch (error) {
         this.warn(error);
         this.showLoading(false);
       }
    },
    // async getCoverImages(){
    //   try {
    //     let collectionsObjects = ['coverImagesList'];
    //     let params = "with[]=" + collectionsObjects.join("&with[]=");
    //     let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
    //     this.coverImagesList = res.data.coverImagesList;
    //   } catch (e) {
    //     this.warn(e);
    //   }
    // },
    mergedStatusName(project){
      // si el estatus del proyecto es 5, 6 o 10, se muestra el nombre del estatus de proyecto
      if(project.project_status === ProjectStatusEnum.FUNDING || project.project_status === ProjectStatusEnum.SIGNING_CONTRACTS || project.project_status === ProjectStatusEnum.REFUND){
        return this.projectStatusName(project.project_status);
      }
      // si el estatus del proyecto es 7, se combinan los nombres de ambos
      else if(project.project_status === ProjectStatusEnum.INSTALLING) {
        let nn = this.projectStatusName(project.project_status);
        nn += ", ";
        nn += this.creditStatusName(project.asset_status, project.status);
        return nn;
      }
      else {
        return this.creditStatusName(project.asset_status, project.status);
      }
    },
    mergedStatusOrder(project){
      if(project.project_status < ProjectStatusEnum.DELIVERED ){
        return project.project_status;
      }
      else {
        return this.creditStatusOrder(project.asset_status, project.status);
      }
    },
    mergedCreditStatusClass(project){
      if(project.project_status < ProjectStatusEnum.DELIVERED ){
        return this.projecStatusClass(project.project_status);
      }
      else {
        return this.creditStatusClass(project.asset_status, project.status);
      }
    },
    setProjects(projects){
      projects.forEach(f => {
        f.name = f.alias.trim();
        f.statusFormat = this.mergedStatusName(f);
        f.statusSort = this.mergedStatusOrder(f);
        f.statusClass = this.mergedCreditStatusClass(f);
        f.investmentFormat = this.explicitMoneyFormat(f.investment, 2);
        f.monthlyFormat = this.explicitMoneyFormat(f.monthly_payment, 2);
        f.progressFormat = `${f.lastPayment} de ${f.totalPayments}`;
        f.progress = f.lastPayment / f.totalPayments;
        f.percent = this.currencyFormat(f.annual_interest *100, 2);
        f.percentFormat = `${f.percent}%`;
        f.estimated = this.calculateEstimated(f);
        f.estimatedFormat = this.explicitMoneyFormat(f.estimated, 2);
        // f.cover_name = this.getCoverName(f.cover_image_type);
      });

      projects.sort(function (a,b){
        return b.id - a.id;
      });
      return projects;
    },
    setMonthEstimations(){
      this.projects.forEach(f => {
        if(f.cut_day === 2){
          this.estimatedCut2 += f.estimated;
        }
        else if(f.cut_day === 16){
          this.estimatedCut16 += f.estimated;
        }
        this.totalEstimated += f.estimated;
      });
    },
    // getCoverName(cover_image_type){
    //   if(cover_image_type != null){
    //     const cc = this.coverImagesList.find(f => f.id === cover_image_type);
    //     if(cc != null){
    //       return cc.name;
    //     }
    //   }
    //   return "-";
    // },
    calculateEstimated(project){
      if(project.financed_amount !== undefined && project.financed_amount !== null && project.investment !== undefined && project.monthly_payment !== undefined){
        return (project.investment/project.financed_amount) * project.monthly_payment;
      }
      return 0;
    },
    async onRowSelected(tr){
      await this.goToProjectDetail(tr.id);
    },
    async goToProjectDetail(id){
      await this.$router.push({name: 'proyectoInversionista', params: {id: id}});
    }
  }
}
</script>


<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 10px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
            &.calif-container {
               height: 70px;
            }

          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
