<template>
  <div class="invested-project-card fund">
    <div class="project-card-img-section cursor-pointer" @click="onExecuteAction">
      <img :src="'https://cdn.redgirasol.com/proyectos/cover/' + proj.cover_image_file" alt="card">
    </div>
    <div class="project-card-content">
      <div class="vx-row mb-2">
        <div class="vx-col w-full text-left">
          <h2>{{proj.alias}}</h2>
          <label>{{proj.cover_name}}</label>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full text-center">
          <label>Tu inversión:</label>
          <div class="project-info-pill pill-white text-dark mt-1 text-xl py-2">
            {{proj.investmentFormat }} MXN
          </div>
        </div>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col md:w-2/3 w-full pr-1">
          <div class="project-info-pill pill-black mb-1 py-2">
            {{proj.monthlyFormat }} MXN
          </div>
          <label>Mensualidad</label>
        </div>
        <div class="vx-col md:w-1/3 w-full pl-1">
          <div class="project-info-pill pill-black py-2">
            {{ proj.progressFormat}}
          </div>
          <label>Avance</label>
        </div>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col md:w-2/3 w-full pr-1">
          <div class="project-info-pill pill-black mb-1 py-2">
            {{proj.percentFormat }} anual
          </div>
          <label>Tasa</label>
        </div>
        <div class="vx-col md:w-1/3 w-full pl-1">
          <div class="project-info-pill text-white no-border mb-1 py-2" :class="`bg-${qualificationCode(proj.qualification)} border-${qualificationCode(proj.qualification)}`">
            {{proj.qualification}}
          </div>
          <label>Calif.</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qualificationsHelper from '@mixins/qualificationsHelper';
export default {
  name: 'InvestedProjectCard',
  mixins: [qualificationsHelper],
  props: ["proj"],
  methods: {
    async onExecuteAction(){
      this.$emit('on-card-clicked', this.proj.id);
    }
  }
}
</script>


<style scoped lang="scss">
.invested-project-card{
  width: 350px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 5px 25px -10px #3b3a3e;
  line-height: normal;
  margin-bottom: 30px;
  text-align: center;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;

}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.invested-project-card .project-card-content{
  padding: 0 15px 15px 15px;
}
.invested-project-card .project-card-content label{
  font-size: 14px;
  margin: 0;
}
.invested-project-card .project-card-img-section{
  background: none;
  position: relative;
}

.invested-project-card .project-card-img-section img{
  width: 350px;
  height: auto;
  bottom: 0;
  right: 0;
}

.invested-project-card .project-card-img-section .show-more-button{
  width: 40px;
  height: 40px;
  position: absolute;
  background-color: #3b3a3e;
  color: white;
  margin: auto;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.invested-project-card .project-card-img-section .show-more-button label{
  font-size: 35px;
  margin: 0 auto;
  line-height: 40px;
  cursor: pointer;
}

.invested-project-card h2{
  margin: 10px 0 0 0;
}

.invested-project-card .project-info-pill{
  width: 100%;
  border: solid 2px #3b3a3e;
  border-radius: 125px;
  padding: 5px 0;
  font-family: 'gilroyextrabold';
  font-size: 16px;
}

.invested-project-card a .project-info-pill{
  color: white;
}

.invested-project-card a .project-info-pill:hover{
  color: white;
}

.invested-project-card .project-info-pill.pill-black{
  color: white;
  background-color: #3b3a3e
}

.invested-project-card .project-info-pill span{
  font-family: 'gilroyextrabold';
  font-size: 20px;
  color: #28DE18;
}

.invested-project-card .project-info-pill.pill-qualification{
  border: none;
  padding: 7px 0;
}
</style>
